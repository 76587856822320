import { Fragment, useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import { Close, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import { useAccountContext } from "@root/stores/accountStore";
import { Logo } from "../common/Logo";
import { navigate } from "vike/client/router";
import { LoadingOverlay } from "../common/LoadingOverlay";
import { UserRoutes } from "./UserRoutes";
import { OrganizationRoutes } from "./OrganizationRoutes";
import { MenuRoute } from "./MenuRoute";

type MenuDrawerProps = {
  open: boolean;
  toggleDrawer: (open: boolean) => void;
};
export const MenuDrawer = observer(
  ({ open, toggleDrawer }: MenuDrawerProps) => {
    const accountStore = useAccountContext();
    const [isNavigating, setIsNavigating] = useState(false);
    const sessionId = useMemo(
      () => accountStore.session?.id,
      [accountStore.session]
    );

    const [routes, setRoutes] = useState<MenuRoute[]>(
      accountStore.isUserProfile ? UserRoutes : OrganizationRoutes(sessionId)
    );

    const expandMenuItem = useCallback(
      (route: MenuRoute) => {
        const index = routes.indexOf(route);
        routes[index].expanded = !route.expanded;
        setRoutes([...routes]);
      },
      [routes]
    );

    const getMenuListItem = (route: MenuRoute) => (
      <ListItem disablePadding>
        <ListItemButton
          onClick={async () => {
            if (route.onClick) {
              setIsNavigating(true);
              toggleDrawer(false);
              route.onClick();
              setIsNavigating(false);
            } else {
              setIsNavigating(true);
              toggleDrawer(false);
              await navigate(route.href!);
              setIsNavigating(false);
            }
          }}
        >
          {route.icon && <ListItemIcon>{route.icon()}</ListItemIcon>}
          <ListItemText primary={route.text} />
        </ListItemButton>
      </ListItem>
    );

    return (
      <Fragment>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "1rem 0",
              minWidth: "24rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  padding: "0 1rem",
                }}
              >
                <Logo />
                <IconButton onClick={() => toggleDrawer(false)}>
                  <Close />
                </IconButton>
              </Box>
              <List sx={{ paddingBottom: 0 }}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={async () => {
                      setIsNavigating(true);
                      toggleDrawer(false);
                      window.location.href = "/";
                      setIsNavigating(false);
                    }}
                  >
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    <ListItemText primary="Discover Events" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Divider />
            <List>
              {routes.map((route, i) => (
                <Fragment key={route.href}>
                  {route.expandable ? (
                    <>
                      {route.expanded && (
                        <Divider sx={{ height: "2px", background: "#BBB" }} />
                      )}
                      <ListItem disablePadding>
                        <ListItemButton onClick={() => expandMenuItem(route)}>
                          {route.icon && (
                            <ListItemIcon
                              sx={(theme) => ({
                                color: route.expanded
                                  ? theme.palette.primary.main
                                  : "rgba(0, 0, 0, 0.54)",
                              })}
                            >
                              {route.icon()}
                            </ListItemIcon>
                          )}
                          <ListItemText primary={route.text} />
                          {route.expanded ? (
                            <ExpandLess
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                              })}
                            />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                      </ListItem>
                      <Collapse
                        in={route.expanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {route.subRoutes?.map((subRoute, j) => (
                            <Fragment key={`route${i}_${j}`}>
                              {getMenuListItem(subRoute)}
                            </Fragment>
                          ))}
                        </List>
                      </Collapse>
                      {route.expanded && (
                        <Divider sx={{ height: "2px", background: "#BBB" }} />
                      )}
                    </>
                  ) : (
                    getMenuListItem(route)
                  )}
                </Fragment>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        <LoadingOverlay show={isNavigating} />
      </Fragment>
    );
  }
);
