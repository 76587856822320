import React, { lazy, PropsWithChildren, useMemo } from "react";
import { PageContextProvider } from "./usePageContext";
import type { PageContext } from "vike/types";
import { MainLayout } from "@root/components/layout/MainLayout";
import { AccountContext, AccountStore } from "@root/stores/accountStore";
import { EventPageContext, EventPageStore } from "@root/stores/eventPageStore";

const AccountSuspendedModal = lazy(() =>
  import("@components/modals/AccountSuspendedModal").then((module) => ({
    default: module.AccountSuspendedModal,
  }))
);

const AuthModal = lazy(() =>
  import("@components/modals/AuthModal").then((module) => ({
    default: module.AuthModal,
  }))
);
const SwitchProfileModal = lazy(() =>
  import("@components/modals/SwitchProfileModal").then((module) => ({
    default: module.SwitchProfileModal,
  }))
);

type PageLayoutProps = PropsWithChildren & {
  pageContext: PageContext;
};

export const PageLayout = ({ children, pageContext }: PageLayoutProps) => {
  const { account } = pageContext;
  const accountStore = useMemo(() => new AccountStore(account), [account]);
  const eventPageStore = useMemo(() => new EventPageStore(), []);

  return (
    <PageContextProvider pageContext={pageContext}>
      <AccountContext.Provider value={accountStore}>
        <EventPageContext.Provider value={eventPageStore}>
          <React.Suspense>
            {accountStore && (
              <MainLayout store={accountStore}>
                {children}
                {account?.blockDate && (
                  <AccountSuspendedModal accountStore={accountStore} />
                )}
                <React.Suspense>
                  <AuthModal />
                </React.Suspense>
                {accountStore?.switchProfileModalStore && (
                  <React.Suspense>
                    <SwitchProfileModal
                      store={accountStore.switchProfileModalStore}
                    />
                  </React.Suspense>
                )}
              </MainLayout>
            )}
          </React.Suspense>
        </EventPageContext.Provider>
      </AccountContext.Provider>
    </PageContextProvider>
  );
};
