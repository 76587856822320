import { Box, useMediaQuery, useTheme } from "@mui/material";
import { MainNavigation } from "../navigation/MainNavigation";
import { Fragment, PropsWithChildren, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { AccountStore } from "@root/stores/accountStore";
import { LoggedInNavigation } from "../navigation/LoggedInNavigation";
import { usePageContext } from "@root/renderer/usePageContext";
import { isAccountUrlPath } from "@root/utils/functions";

export const MainLayout = observer(
  ({ store, children }: PropsWithChildren<{ store: AccountStore }>) => {
    const { urlPathname } = usePageContext();
    const theme = useTheme();
    const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { authModalStore } = store;

    const showLogin = useCallback(
      () => authModalStore.openLoginModal(),
      [authModalStore]
    );
    
    const showSignUp = useCallback(
      () => authModalStore.openAuthModal("register"),
      [authModalStore]
    );

    return (
      <Fragment>
        {store?.account ? (
          <LoggedInNavigation />
        ) : (
          <MainNavigation onLoginClick={showLogin} onSignUpClick={showSignUp} />
        )}
        <Box
          sx={(theme) => ({
            padding: isAccountUrlPath(urlPathname) && mediumScreen ? "0" : isAccountUrlPath(urlPathname) ? "1.5rem" : "0",
            height: "calc(100% - 64px)",
            [theme.breakpoints.down("md")]: {
              height: "calc(100% - 56px)",
            },
            [theme.breakpoints.down("sm")]: {
              height: "calc(100% - 48px)",
            },
          })}
        >
          {children}
        </Box>
      </Fragment>
    );
  }
);
