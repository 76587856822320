import { computed, makeAutoObservable } from "mobx";
import { DateRange, NumberRange } from "./types";

export class Session {
  constructor(
    id: string,
    name: string,
    type: "user" | "organization",
    role?: string
  ) {
    makeAutoObservable(this, {
      initials: computed
    });
    this.id = id;
    this.name = name;
    this.type = type;
    this.role = role;
  }

  id: string;
  name: string;
  type: "user" | "organization" = "user";
  role?: string;

  updateSession(
    id: string,
    name: string,
    type: "user" | "organization",
    role?: string
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.role = role;
  }

  get initials() {
    return this.name
      ?.split(" ")
      .map((s) => s.substring(0, 1))
      .slice(0, 2)
      .join("");
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      role: this.role,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromJSON(json: any) {
    return new Session(json.id, json.name, json.type, json.role);
  }
}

export class Account {
  constructor() {
    makeAutoObservable(this, {
      fullName: computed,
    });
  }

  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  emailConfirmed: boolean;
  imageUrl?: string;
  phone?: string;
  blockDate?: Date | string;
  profiles?: Session[];

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export type User = Account;
export class OrganizationUser extends Account {
  constructor() {
    super();
    makeAutoObservable(this);
  }

  organizationId: string;
  role: string;
  organization?: Organization;
}

export class Organization {
  constructor() {
    makeAutoObservable(this);
  }

  id: string;
  userId: string;
  name: string;
  email?: string;
  address?: Address;
  website?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  twitterUrl?: string;
  role?: Role;
}

export class OrganizationInvite {
  constructor() {
    makeAutoObservable(this);
  }

  id: string;
  userId?: string;
  email?: string;
  role?: string;
  acceptedOn?: Date;
}

export class Role {
  id: string;
  name: string;
}

export class Event {
  constructor() {
    makeAutoObservable(this);
  }

  id: string;
  userId: string;
  organizationId: string;
  locationId: string;
  categories: Category[];
  name: string;
  locationName: string;
  description?: string;
  parkingInfo?: string;
  dateRange?: DateRange;
  location?: Location;
  eventLink?: string;
  bannerImageUrl?: string;
  ageRange?: NumberRange;
  pricing: Pricing[];
  organization?: Organization;
  user?: User;
}

export class Category {
  constructor(id?: string) {
    makeAutoObservable(this);
    this.id = id;
  }

  id?: string;
  name: string;
  parentCategoryId?: string;
  parentCategory?: Category;
}

export class Pricing {
  constructor(description?: string, price?: number) {
    makeAutoObservable(this);
    this.description = description;
    this.price = price;
  }

  id?: string;
  price?: number;
  description?: string;
}

export class Location {
  constructor() {
    makeAutoObservable(this);
  }

  id: string;
  address: Address;
  coordinate: Coordinate;
}

export class Coordinate {
  constructor() {
    makeAutoObservable(this);
  }

  latitude?: string;
  longitude?: string;
}

export class Address {
  constructor() {
    makeAutoObservable(this);
  }

  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  htmlDisplay?: string;
  textDisplay?: string;
}

export class Order {
  constructor() {
    makeAutoObservable(this);
  }

  id: string;
  userId: string;
  organizationId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  clientIP?: string;
  couponCode?: string;
  couponAmount?: number;
  subtotal?: number;
  tax?: number;
  total?: number;
}

export class Coupon {
  constructor(type: string, amount: number, code: string) {
    makeAutoObservable(this, {
      
    });

    this.type = type;
    this.amount = amount;
    this.code = code;
  }

  id?: string;
  type?: string;
  amount: number;
  code?: string;
}