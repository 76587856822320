import {
  AddShoppingCart,
  Bookmark,
  Edit,
  Event,
  People,
  Visibility,
} from "@mui/icons-material";

export const OrganizationRoutes = (organizationId?: string) => [
  {
    text: "View Organization",
    href: `/?organization=${organizationId}`,
    icon: () => <Visibility />,
  },
  {
    text: "Edit Organization",
    href: `/account/organizations/${organizationId}`,
    icon: () => <Edit />,
  },
  { text: "My Events", href: "/account/events", icon: () => <Event /> },
  {
    text: "Credits",
    href: "/account/credits/purchase",
    icon: () => <AddShoppingCart />,
  },
  {
    text: "Users",
    href: `/account/organizations/${organizationId}/users`,
    icon: () => <People />,
  },
  {
    text: "Followers",
    href: `/account/organizations/${organizationId}/followers`,
    icon: () => <Bookmark />,
  },
];
