import { Fragment } from "react";
import { AppBar, Box, Button, Toolbar } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Logo } from "../common/Logo";

interface MainNavigationProps {
  onLoginClick: () => void;
  onSignUpClick: () => void;
}
export const MainNavigation = observer(
  ({ onLoginClick, onSignUpClick }: MainNavigationProps) => (
    <Fragment>
      <AppBar sx={{ padding: 0 }}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: "3rem",
              width: "100%",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                px: "1rem",
                justifyContent: "start",
              },
            })}
          >
            <Logo />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: "1.5rem",
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  height: "100%",
                }}
              >
                <Button variant="text" type="button" onClick={onLoginClick}>
                  Login
                </Button>
                <Button
                  type="button"
                  onClick={onSignUpClick}
                  sx={{ padding: "0.5rem 1rem" }}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  )
);
